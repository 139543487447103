.quotes-container {
  position: relative;
  width: 60%;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
}

.main-qoute {
  direction: rtl;
  color: black;
  text-align: center;
  padding: 2rem 3rem;
  margin-bottom: 1rem;
}

.qoute-from {
  position: absolute;
  bottom: 0.5rem;
  left: 1rem;
  font-size: 1.2rem;
}
