.rating-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.ratings-box-container {
  background-color: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.418);

  .rating-score {
    color: #02275b;
    font-weight: 500;
  }

  margin-bottom: 0.5rem;
}

.rating-website {
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  text-shadow: 0 2px 2px black;
}
