.navbar-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 10vh;
  min-height: 100px;
  display: flex;
  background: rgba(53, 75, 60, 0.5);
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  box-shadow: 0 5px 15px -5px #00000047;
  z-index: 99;
  ul {
    width: 60%;
    min-width: 800px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  li {
    color: white;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.212);
    margin-left: 1rem;
  }

  .contact-btn {
    text-shadow: none;
  }

  .logo-li {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .logo-trasparent {
    opacity: 0;
  }
}

.navbar-logo-tablet {
  display: none;
  // Tablet
  @media screen and (max-width: 850px) {
    display: inherit;
  }
}
