.burger-menu-container {
  width: 50px;
  height: 50px;
  //   background-color: #4a9534;
  z-index: 99;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 2rem;
  @media screen and (max-width: 850px) {
    display: flex;
  }
}

.burger-menu-icon-container {
  transform: scaleX(-1);

  div {
    width: 35px;
    height: 3px;
    background-color: white;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
  }

  div:nth-child(2) {
    width: 30px;
  }

  div:last-child {
    margin-bottom: 0;
    width: 25px;
  }
}

.burger-side-menu-container {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgb(221, 243, 227);
  z-index: 999;
  box-shadow: -10px 0 15px rgba(0, 0, 0, 0.253);
}

.close-btn-side-menu {
  font-size: 3rem;
  left: 5rem !important;
  color: #100f16;
}

.burger-menu-ul {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  height: 60%;
  li a {
    font-size: 2.3rem;
    color: #100f16;
  }
}

.burger-menu-logo {
  padding-bottom: 4rem;
}
