@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Rubik:wght@400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: white;
  direction: rtl;
}

p,
span {
  direction: rtl;
}
html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Rubik", sans-serif;
  font-size: 1.8rem;
  background: rgb(223, 223, 223);
  background: linear-gradient(180deg, rgba(223, 223, 223, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.no-scroll {
  overflow-y: hidden;
}
