.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  h1 {
    display: inline;
    font-family: "Courgette", cursive;
    font-size: 4rem;
    color: white;
    font-weight: 400;
    text-align: center;
    line-height: 5rem;

    @media screen and (max-width: 450px) {
      font-size: 3rem;
    }
  }

  span {
    display: inline;
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: -1rem;
    color: white;
    text-align: center;

    @media screen and (max-width: 450px) {
      font-size: 1.3rem;
    }
  }
}

.logo-blue {
  h1,
  span {
    color: #100f16;
  }
}
