@import "~react-image-gallery/styles/scss/image-gallery.scss";

.image-gallery {
  border-radius: 1rem !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.137) !important;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.image-gallery-slide img {
  height: 400px;
  max-width: 100% !important;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen {
  min-height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.fullscreen .image-gallery-slide img {
  height: 70vh !important;
}

.fullscreen .image-gallery-slide {
  width: 100% !important;
}
