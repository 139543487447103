.exp-section-container {
  min-height: 100vh;
  background-image: url("../../assets/jecuzzi.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap-reverse;
  width: 100%;
  padding: 10rem 4rem 0 4rem;

  @media screen and (max-width: 1100px) {
    padding: 12rem 4rem 0 4rem;
  }
}

.exp-section-right {
  height: 100%;
  flex: 1 1 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  width: 100%;

  h3 {
    margin-bottom: 2rem;
  }
}
.exp-section-content-left {
  height: 100%;
  flex: 1 1 60rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;

  h3 {
    margin-bottom: 2rem;
  }

  .exp-content-p {
    text-align: right;
    margin-bottom: 2rem;
    width: 500px;
    border-radius: 1rem;

    @media screen and (max-width: 600px) {
      background: rgba(255, 255, 255, 0.6);
      padding: 2rem;

      width: 100%;
      text-align: right;
    }
  }
}

.exp-title-p-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 4rem;
  border-radius: 1rem;

  @media screen and (max-width: 600px) {
    justify-content: center;
    align-items: center;
  }
}

.room-types-container {
  padding-right: 4rem;

  display: grid;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    padding-right: 0;
    .room-card-flex-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
